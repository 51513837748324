<template>
    <LiefengContent>
        <template #title>定制功能或报表</template>
        <template #toolsbarRight></template>
        <template #contentArea>
            <Button type="primary" icon="ios-add" class="btnstyle" @click="add">我要创建新的功能或报表</Button>
            <Divider>我已有的功能或报表</Divider>
            <Input style="width:352px" search enter-button placeholder="请输入" />
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

                <!-- :fullscreen="true"  -->
            <LiefengModal 
                :value="addStatus" 
                title="自定义固定业务功能"
                @input="changeAddStatus"
                width="90%"
                height="calc(100vh - 320px)"
            >
                <template v-slot:title>
                        <span>① 功能名称及描述</span> <Input v-model="value" placeholder="名称限15字" style="width: 353px;margin-left: 10px;" />
                        <Input v-model="value" type="textarea" placeholder="还有其他想写的写到描述这里，限100字" style="display: block; width: 464px;margin: 10px 0;" />
                        
                        <div style="display:flex ;width:100%;justify-content: space-between;line-height:32px">
                            <p style="display:flex ;justify-content: space-between">
                                <span>② 请勾选需要的业务字段</span>
                                <Input v-model.trim="fieldKeys" placeholder="按名称搜索" style="width: 300px;margin-left: 10px;" search enter-button="搜索" @on-search="searchField"/>
                            </p>
                            <span>
                                ③ 确定已选择的业务字段（多的可在左侧取消勾选）
                            </span>
                            
                        </div>
                </template>
                <template v-slot:contentarea>
                    <Fieldlist :fieldKeys="fieldKeys"></Fieldlist>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin-right: 10px" type="primary" @click="preview">生成预览效果</Button>
                </template>
            </LiefengModal>

            <LiefengModal 
                :value="previewStatus" 
                @input="changePreviewStatus"
                :toggle="true"
                width="90%"
                height="400px"
            >
                <template v-slot:contentarea>
                    <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :hidePage="true"
                    :fixTable="true"
            ></LiefengTable>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin-right: 10px" type="primary" @click="saveEdit">是我想要的，保存</Button>
                    <Button style="margin-right: 10px" type="info" @click="changePreviewStatus(false)">少了字段，返回增加</Button>
                    <Button style="margin-right: 10px" type="info" @click="closeEdit">勾选中缺少我需要的，申请创建</Button>
                </template>
            </LiefengModal>

            <LiefengModal 
                :value="importStatus" 
                @input="changeImportStatus"
                title="导入"
                width="600px"
                height="210px"
            >
                <template v-slot:contentarea>
                    <LiefengUploadExcel
                        style="margin: 20px 0"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/popularSentiment.xlsx', downName: '民情档案导入模板' }"
                        :headers="headers"
                        :action="exportAction"
                        :uploadData="uploadData"
                        :isShow="showExcel"
                        @success="excelSuccess"
                    ></LiefengUploadExcel>
                </template>
                <template v-slot:toolsbar>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import Fieldlist from "./children/fieldlist"
import LiefengUploadExcel from "@/components/LiefengUploadExcel"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        Fieldlist,
        LiefengUploadExcel
    },
    data() {
        return {
            search: {
                userName: "",
                mobile: "",
                status: "全部",
            },

            tableData: [{}],
            talbeColumns: [
                {
                    title: "功能名称",
                    key: "staffName",
                    width: 300,
                    align: "center",
                },
                {
                    title: "状态",
                    key: "orgName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "可见范围",
                    key: "accessName",
                    minWidth: 300,
                    align: "center",
                },

                {
                    title: "最后更新",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    fixed: "right",
                    title: "操作",
                    width: 400,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            layer.open({
                                                type: 2,
                                                title: "",
                                                content: `/page#/reportdatamanage`,
                                                area: ["99%", "90%"],
                                                
                                            },()=>{  
                                                
                                            })
                                        },
                                    },
                                    class:'btnitem'
                                },
                                "查看数据"
                            ),
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            this.import()
                                        },
                                    },
                                    class:'btnitem'
                                },
                                "导入"
                            ),
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            this.export(params.row.id)
                                        },
                                    },
                                    class:'btnitem'
                                },
                                "导出"
                            ),
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            this.changeStatus(params.row.id)
                                        },
                                    },
                                    
                                    class:'btnitem'
                                },
                                "停用"
                            ),
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            this.getDetails(params.row.id)
                                        },
                                    },
                                    class:'btnitem'
                                },
                                "编辑"
                            ),
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            this.qrCode(params.row.id)
                                        },
                                    },
                                    class:'btnitem'
                                },
                                "二维码"
                            ),
                        ])
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,

            //定义
            addStatus:false,
            fieldKeys:'',

            //预览
            previewStatus:false,
            pTableData:[],
            pTalbeColumns:[],

            //导入
            importStatus:false,

        }
    },

    methods: {
        //分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/verifyapi/api/auth/pc/sensitive/querySensitiveLog", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                page: this.page,
                pageSize: this.pageSize,
            }).then(res => {
                this.loading = false
                if (res.code == 200 && res.dataList) {
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.pageSize = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                }
            })
        },
        changeAddStatus(val){
            this.addStatus = val
        },
        add(){
            this.addStatus = true
        },
        searchField(){
            console.log(this.fieldKeys)
        },
        
        changePreviewStatus(val){
            this.previewStatus = val
        },
        // 预览
        preview(){
            this.previewStatus = true
            return
            this.$Message.warning({
                content:'请勾选字段',
                background:true
            })
        },

        //保存
        saveEdit(){
            this.$Message.success({
                content:'保存成功',
                background:true
            })
            this.previewStatus = false
            this.addStatus = false
        },

        // 导入
        import(){
            this.importStatus = true
        },
        changeImportStatus(val){
            this.importStatus = val
        },
        // 导出
        export(){
            
        },
        
        // 停用
        changeStatus(){

        },
        // 编辑
        getDetails(){

        },
        // 二维码
        qrCode(){

        },
    },
    created() {
        this.getList()
    },
}
</script>
    
<style scoped lang='less'>
/deep/.btnitem{
    text-align: center ;
    display:inline-block;
    color:#448BEF;
    padding: 0 10px;
    cursor: pointer;
}
.btnstyle {
    margin-top: 10px;
    width: 300px;
    height: 50px;
    font-size: 18px;
}
.ivu-divider {
    color: #29be6c;
    &::before {
        border-top: 3px solid #29be6c;
    }
    &::after {
        border-top: 3px solid #29be6c;
        
    }
}
/deep/#title{
    margin:10px !important
}
</style>