<template>
    <div class="content">
        <div class="fieldbox">
            <ul class="field-list">
                <li style="position: sticky;top:0;z-index:99;background-color: #fff;">
                    <div class="label column">
                        标签页
                    </div>
                    <div class="fieldname column">
                        字段
                    </div>
                </li>
                <li v-for="(item, index) in templateField" :key="index">
                    <div class="label">
                        <Checkbox v-model="item.status" @on-change="val => changeLabel(val, item.formName)">{{ item.formName }}</Checkbox>
                    </div>
                    <div class="field">
                        <Checkbox v-model="e.status" v-for="(e, i) in item.authFieldList" :key="i" @on-change="val => changeField(val, item.formName)">{{ e.fieldName }}</Checkbox>
                    </div>
                </li>
            </ul>
        </div>
        <div class="check">
            <p v-for="(item, index) in selectList" :key="item.fieldId">{{ index + 1 }}. {{ item.fieldName }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fieldKeys: String,
    },
    data() {
        return {
            templateField: [],
            selectList: [],
        }
    },
    created() {
        this.getField()
    },
    methods: {
        getField() {
            this.$get("/syaa/api/syuser/pc/residentArchive/selectExportField").then(res => {
                if (res.code == 200) {
                    let arr = res.dataList
                    arr.forEach(e => {
                        e.status = false
                        e.authFieldList.forEach(item => {
                            item.status = false
                        })
                    })
                    arr.map(e => {
                        switch (e.formId) {
                            case "1":
                                e.formName = "人员基本信息"
                                break
                            case "2":
                                e.formName = "人员详情信息"
                                break
                            case "3":
                                e.formName = "网上家园信息"
                                break
                            case "4":
                                e.formName = "党员信息"
                                break
                            case "5":
                                e.formName = "志愿者信息"
                                break
                            case "6":
                                e.formName = "居民小组信息"
                                break
                            case "7":
                                e.formName = "健康需求评估"
                                break
                            case "8":
                                e.formName = "特殊困难群体信息"
                                break
                            case "9":
                                e.formName = "低保申请信息"
                                break
                            case "10":
                                e.formName = "孤独老人信息"
                                break
                            case "11":
                                e.formName = "残疾人信息"
                                break
                            case "12":
                                e.formName = "退役军职信息"
                                break
                            case "13":
                                e.formName = "疫情防控档案信息"
                                break
                        }
                    })
                    let arr2 = arr.sort(function(a, b) {
                        return a.formId - b.formId
                    })

                    this.templateField = arr2
                    console.log(this.templateField)
                }
            })
        },
        changeLabel(val, type) {
            this.templateField.forEach(item => {
                if (item.formName == type) {
                    item.authFieldList.forEach(e => {
                        e.status = val
                    })
                }
            })
        },
        changeField(val, type) {
            this.templateField.forEach(item => {
                if (item.formName == type) {
                    item.status = item.authFieldList.every(e => e.status === true)
                }
            })
            // let arr = document.getElementsByClassName('field')
            //         arr.forEach( item => {
            //             item.children.forEach( e => {
            //                 if(e.innerHTML.indexOf(val) != -1 && this.fieldKeys){
            //                     // e.className = 'ivu-checkbox-wrapper ivu-checkbox-default redfs'
            //                     e.classList.add("redfs")
            //                 }else{
            //                     e.classList.remove("redfs")
            //                 }
            //             })
            //         })
        },
    },
    watch: {
        templateField: {
            handler(val) {
                if (val) {
                    this.selectList = []
                    val.forEach(item => {
                        item.authFieldList.forEach(e => {
                            if (e.status) this.selectList.push(e)
                        })
                    })
                }
            },
            deep: true,
        },
        fieldKeys: {
            // handler(val){
            //     // if(val){
            //         let arr = document.getElementsByClassName('field')
            //         arr.forEach( item => {
            //             item.children.forEach( e => {
            //                 if(e.innerHTML.indexOf(val) != -1 && val){
            //                     // e.className = 'ivu-checkbox-wrapper ivu-checkbox-default redfs'
            //                     e.classList.add("redfs")
            //                 }else{
            //                     e.classList.remove("redfs")
            //                 }
            //             })
            //         })
            //     // }
            // }
        },
    },
}
</script>

<style lang="less" scoped>
.content {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 400px);
    height: 100%;
    .fieldbox {
        // flex: 5;
        width: 100%;
        margin-right: 20px;
        height: calc(100vh - 400px);
        border: 1px solid #999999;
        overflow-y: scroll;
        .field-list {
            li {
                &:nth-of-type(even) {
                    background-color: #f8f8f9;
                }
                padding: 5px 0 5px 30px;
                display: flex;
                .label {
                    font-size: 16px;
                    font-weight: 700;
                    width: 200px;
                    text-align: right;
                    padding-right: 40px;
                    box-sizing: border-box;
                }
                .fieldname {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                }
                .field {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    .ivu-checkbox-wrapper {
                        width: 230px;
                        margin: 3px 0;
                    }
                }
                .column {
                    color: #4fcb8a !important;
                    font-size: 18px !important;
                    font-weight: 700 !important;
                }
            }
        }
    }
    .check {
        width: 430px;
        // flex: 2;
        height: calc(100vh - 400px);
        border: 1px solid #999999;
        overflow-y: scroll;
        p {
            margin-left: 30px;
            line-height: 40px;
        }
    }
    .redfs {
        color: red;
    }
}
</style>
